import React from "react";
import Layout from "../components/layout";
import SearchBox from "../components/contentElements/searchBox";
import H1 from "../components/htmlElements/h1";

function LeftColumnContent() {
  return (
    <>
      <H1 innerContent="Request material" additionalClasses="pb-5" />
    </>
  );
}

export default function IndexRoute() {
  return (
    <Layout>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-7">
          <LeftColumnContent />
        </div>

        <div className="col-span-12 md:col-span-5">
          <SearchBox />
        </div>
      </div>
    </Layout>
  );
}
